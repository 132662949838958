<template>
  <div>
    <v-data-table :headers="headers" :items="entries" sort-by="name" class="elevation-1" dense v-model="selectedRows"
      :search="filter">
      <template v-slot:top>
        <v-toolbar flat>
          <help />
          <v-toolbar-title> {{ $t("entity.dateTest.head") }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-card-title>
            <v-text-field v-model="filter" append-icon="mdi-magnify" :label="$t('common.search')" single-line
              hide-details />
          </v-card-title>
          <v-spacer />
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                {{ $t("common.button.add") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left" />
                <v-divider class="mx-1" />
                <v-container>
                  <v-form ref="form_data" v-model="valid">
                    <v-row>
                      
<v-col cols="12" sm="6" md="6">
<v-menu v-model="menuDatum" :close-on-content-click="false" :nudge-right="40"
    transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, datums }">
    <v-text-field v-model="formatDatum" :label="$t('entity.dateTest.datum.middel')"
        prepend-icon="mdi-calendar" readonly v-bind="datums" v-on="on"
        @click:clear="editedItem.datum = null" clearable :rules="rules.datum"></v-text-field>
    </template>
    <v-date-picker v-model="editedItem.datum" @input="menuDatum = false"></v-date-picker>
</v-menu>
</v-col>


<v-col cols="12" sm="6" md="6">
<v-text-field :value="editedItem.ad" v-model="editedItem.ad" :rules="rules.ad"
    :label="$t('entity.dateTest.ad.middel')" :hint="$t('entity.dateTest.ad.hint')" />
</v-col>

<v-col cols="12" sm="6" md="6">
<v-text-field :value="editedItem.superClassAttribute" v-model="editedItem.superClassAttribute" :rules="rules.superClassAttribute"
    :label="$t('entity.dateTest.superClassAttribute.middel')" :hint="$t('entity.dateTest.superClassAttribute.hint')" />
</v-col>

<v-col cols="12" sm="6" md="6">
<v-text-field :value="editedItem.bez" v-model="editedItem.bez" :rules="rules.bez"
    :label="$t('entity.dateTest.bez.middel')" :hint="$t('entity.dateTest.bez.hint')" />
</v-col>

<v-col cols="12" sm="6" md="6">
<v-text-field :value="editedItem.ident" v-model="editedItem.ident" :rules="rules.ident"
    :label="$t('entity.dateTest.ident.middel')" :hint="$t('entity.dateTest.ident.hint')" />
</v-col>

                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close">
                  {{ $t("common.button.cancel") }}
                </v-btn>
                <v-btn color="primary" text @click="save">
                  {{ $t("common.button.assume") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{
              $t("common.question.delete")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="closeDelete">{{
                $t("common.button.cancel")
                }}</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">{{
                $t("common.button.ok")
                }}</v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" color="error">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.references`]="{ item }">
        <span>{{ formatREF(item.references) }}</span>
        <span>&nbsp;</span>
        <v-icon small class="mr-2" @click="showReferences(item)">
          mdi-clipboard-multiple
        </v-icon>
      </template>
          <template v-slot:[`item.datum`]="{ item }">
        <span>{{ formatDate(item.datum) }}</span>
      </template>
      

    </v-data-table>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn @click="initShow" outlined>{{
          $t("common.button.reload")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Modal v-show="isModalVisible" @close="closeModal" v-bind:editedItem="editedItem" v-bind:vertextType="vertextType"
      v-bind:linkType="linkType" />
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="darklagoon">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amarillo" text v-bind="attrs" @click="snackbar = false">
          {{ $t("common.button.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DataService from "../../services/DateTestDataService.js";
import Help from "@/components/help/DateTestHelp.vue";
import Modal from "../../components/entity/References.vue";
let localeStringBase = "de-DE";
export default {
  components: { Help, Modal },
  data() {
    return {
      localeString: localeStringBase,
      valid: true,
      dialog: false,
      isModalVisible: false,
      dialogDelete: false,
      menuDatum: false,
        formatDatum: null,

      headers: [
        { text: this.$t("entity.dateTest.datum.middel"), value: "datum", filterable: true, sortable: true },
{ text: this.$t("entity.dateTest.ad.middel"), value: "ad", filterable: true, sortable: true },
{ text: this.$t("entity.dateTest.superClassAttribute.middel"), value: "superClassAttribute", filterable: true, sortable: true },
{ text: this.$t("entity.dateTest.bez.middel"), value: "bez", filterable: true, sortable: true },
{ text: this.$t("entity.dateTest.ident.middel"), value: "ident", filterable: true, sortable: true },
{ text: this.$t("entity.dateTest.vertexName.middel"), value: "vertexName", filterable: true, sortable: true },

        {
          text: this.$t("common.field.references.middel"),
          value: "references",
          filterable: false,
          sortable: false,
          dataType: "REF"
        },
        { text: this.$t("common.field.actions.middel"), value: "actions", sortable: false, filterable: false }
      ],
      rules: {
        datum: [v => !!v || this.$t("entity.dateTest.datum.rule")],
superClassAttribute: [v => !!v || this.$t("entity.dateTest.superClassAttribute.rule")],
bez: [v => !!v || this.$t("entity.dateTest.bez.rule")],
ident: [v => !!v || this.$t("entity.dateTest.ident.rule")],
      },
      
      vertextType: "DATETEST",
      linkType: "dateTest",
      entry: [],
      entries: [],
      editedIndex: -1,
      editedItem: {
	datum: "",
	ad: "",
	superClassAttribute: "",
	bez: "",
	ident: "",
	vertexName: null,
	references: []
},
defaultItem: {
	datum: "",
	ad: "",
	superClassAttribute: "",
	bez: "",
	ident: "",
	vertexName: null,
	references: []
},

      isEditModus: false,
      snackbar: false,
      message: "",
      snackbar_timeout: 8000,
      selectedRows: [],
      filter: ""
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("common.question.add") : this.$t("common.question.change");
    },
    formDescription() {
      return this.$t("entity.dateTest.formDescription");
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "editedItem.datum"(val) {
        if (val != "") {
        this.formatDatum = this.formatDate(this.editedItem.datum);
        }
        },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {
    this.initialize(true);
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    
    formatDate(item) {
      if (item === null) {
        return null;
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
      return new Date(item).toLocaleString(this.localeString, options);
    },
    initShow() {
      this.initialize(true);
    },
    initialize(ismessage) {
      this.loadEntries(ismessage);
    },
    async loadEntries(ismessage) {
      try {
        let resp = await DataService.getAll();
        if (resp.status == 200) {
          this.entries = resp.data;
          if (ismessage == true) {
            this.message = this.$t("common.messages.load");
            this.snackbar = true;
          }
        } else {
          this.message = resp.errorMessage;
          if (!this.message) {
            this.message = this.$t("common.messages.noData");
          }
          this.snackbar = true;
        }
      } catch (err) {
        this.message = err;
        this.snackbar = true;
      }
    },
    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.entries.splice(this.editedIndex, 1);
      this.deleteService();
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.valid = true;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.updateService();
        } else {
          this.addService();
        }
        this.close();
      }
    },
    async addService() {
      try {
        let resp = await DataService.create(this.editedItem);
        if (resp.status == 201) {
          this.message = this.$t("common.messages.save");
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize(false);
      }
      this.snackbar = true;
    },
    async updateService() {
      try {
        let resp = await DataService.update(this.editedItem);
        if (resp.status == 200) {
          this.message = this.$t("common.messages.save");
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize(false);
      }
      this.snackbar = true;
    },
    async deleteService() {
      try {
        let resp = await DataService.delete(this.editedItem);
        if (resp.status == 204) {
          this.message = this.$t("common.messages.save");
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize(false);
      }
      this.snackbar = true;
    },
    validate() {
      this.$refs.form_data.validate();
    },
    formatREF(item) {
      if (item === null) {
        return "--- ";
      }
      if (item.length === 0) {
        return "0";
      }
      var outgoing = item.length;
      return this.$t("common.references.directionOut")
        .concat(" ")
        .concat(outgoing)
    },
    closeModal() {
      this.isModalVisible = false;
      this.initialize(true);
    },
    showReferences(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.isModalVisible = true;
    }
  }
};
</script>